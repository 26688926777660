<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-lifetime"
                label-cols-lg="2"
                :label="$t('PRODUCTS.LIFETIME')"
                label-for="field-lifetime-input"
              >
                <b-form-input
                  id="field-lifetime-input"
                  v-model="dto.lifetime"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-validationRegex"
                label-cols-lg="2"
                :label="$t('PASSPHRASE_TYPES.VALIDATION_REGEX')"
                label-for="field-validationRegex-input"
              >
                <b-form-textarea
                  id="field-validationRegex-input"
                  v-model="dto.validationRegex"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                :label="$t('PASSPHRASE_TYPES.ENCRYPTION')"
                label-for="passphrase-encryption"
                label-cols-lg="2"
              >
                <b-form-select
                  v-model="dto.passphraseEncryptionId"
                  id="template-type"
                  :options="passphraseEncryptions"
                >
                </b-form-select>
              </b-form-group>

              <b-form-group
                :label="$t('PASSPHRASE_TYPES.GENERATOR')"
                label-for="passphrase-generator"
                label-cols-lg="2"
              >
                <b-form-select
                  v-model="dto.passphraseGeneratorId"
                  id="template-type"
                  :options="passphraseGenerators"
                >
                </b-form-select>
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "passphrasetype",
  components: {},
  data() {
    return {
      save: {
        resource: "/platform/api/passphrase-type/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        name: null,
        lifetime: null,
        validationRegex: null,
        passphraseEncryptionId: null,
        passphraseGeneratorId: null,
      },

      passphraseTypeRequest: {
        resource:
          "/platform/api/com.gracelogic.platform.user.model.PassphraseType",
        requestType: "GET",
        requestParams: {},
      },
      passphraseEncryptionRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.user.model.PassphraseEncryption",
        requestType: "GET",
        requestParams: {},
      },
      passphraseGeneratorRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.user.model.PassphraseGenerator",
        requestType: "GET",
        requestParams: {},
      },
      passphraseTypes: [],
      passphraseEncryptions: [],
      passphraseGenerators: [],
      showOverlay: true,
      createNewStr: this.$t("PASSPHRASE_TYPES.CREATE_NEW_PASSPHRASE_TYPE"),
      editStr: this.$t("PASSPHRASE_TYPES.EDIT_PASSPHRASE_TYPE"),
    };
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PASSPHRASE_TYPES"), route: "/passphrase-types" },
      { title: title },
    ]);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/passphrase-type/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => (this.dto = response))
        .catch(this.onError);
    }

    this.showOverlay = false;

    this.loadPassphrasesEncryptions();
    this.loadPassphrasesGenerators();
  },

  methods: {
    loadPassphrasesEncryptions: function () {
      this.$store
        .dispatch(API_REQUEST, this.passphraseEncryptionRequest)
        .then((response) => {
          for (const value of response) {
            this.passphraseEncryptions.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },
    loadPassphrasesGenerators: function () {
      this.$store
        .dispatch(API_REQUEST, this.passphraseGeneratorRequest)
        .then((response) => {
          for (const value of response) {
            this.passphraseGenerators.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    onSaveClicked: function () {
      const request = this.save;
      request.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
